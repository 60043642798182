import { render, staticRenderFns } from "./centerResArticle.vue?vue&type=template&id=5c71edfb&scoped=true&"
import script from "./centerResArticle.vue?vue&type=script&lang=js&"
export * from "./centerResArticle.vue?vue&type=script&lang=js&"
import style0 from "./centerResArticle.vue?vue&type=style&index=0&id=5c71edfb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c71edfb",
  null
  
)

export default component.exports