<template>
    <!--  -->
    <el-col :span="12">
        <el-card :body-style="{ padding: '0px' }" shadow="never">
            <!-- 上半部分 -->
            <div class="article_top_box">
                <!-- 图片 -->
                <el-image 
                    :src="item.goods_img"
                    fit="scale-down"
                    class="article-pic"
                    lazy>
                    <template v-slot:error>
                        <div class="article-pic-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <!-- 文字容器 -->
                <div class="article-info">
                    <div class="article_title">{{item.goods_name}}</div>
                    <div class="article_address">{{item.goods_address}}</div>
                    <div class="article_price">¥{{item.price}}</div>
                </div>
                <!-- 是否上架 -->
                <div class="upper_shelf">
                    <!-- 审核状态 -->
                    <img :src="require('@/assets/images/center/@1x/icon_verify.png')" alt="" v-if="item.verified===0">
                    <!-- 上下架 -->
                    <img :src="require('@/assets/images/center/@1x/icon_offshelf.png')" alt="" v-else-if="item.showStatus===0">
                    <img :src="require('@/assets/images/center/@1x/icon_onshelf.png')" alt="" v-else-if="item.showStatus===1">
                </div>
            </div>
            <!-- 下半部分 -->
            <div class="resources_bottom_area">
                <a href="javascript:;"
                    @click="handleDel(item.goods_id)">
                    删除
                </a>
                <b>|</b>
                <a href="javascript:;"
                    @click="$router.push({name:'centerCameraInfo',query:{id:item.goods_id}})">
                    编辑
                </a>
            </div>
        </el-card>
    </el-col>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props:["item"],
    methods: {
        ...mapActions([
            "handleLeaseDel"
        ]),
        handleDel(id){
            this.$confirm("是否删除此服饰？", "提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleLeaseDel(id).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type? "success": "error"
                    })
                })
            }).catch(() => {})
        }
    },
}
</script>

<style scoped>
/* 上半部分盒子 */
.article_top_box{
    position: relative;
    width: 100%;
    height:130px;
    padding:20px;
}
/* 图片 */
.article-pic{
    position:absolute;
    top: 20px;
    left: 20px;
    width: 90px;
    height: 90px;
}
.article-pic>>>.article-pic-slot{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f7fa;
    color: #909399;
    font-size: 30px;
}
/* text容器 */
.article-info{
    margin-left: 100px;
}
/* 是否上架 */
.upper_shelf{
    position: absolute;
    top: 0;
    right: 0;
}
/* 标题 */
.article_title{
    margin-bottom: 10px;
    font-size: 17px;
}
/* 地址 */
.article_address{
    margin-bottom: 5px;
}
/* 价格 */
.article_price>span{
    margin-right: 10px;
    text-decoration:underline;
}
/* 下半部分 */
.resources_bottom_area{
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    height: 40px;
    text-align: center;
}
.resources_bottom_area>a{
    display: inline-block;
    width: 49%;
    height: 100%;
    line-height: 40px;
    color: #909399;
}
.resources_bottom_area>b{
    color: #ddd;
}
</style>
